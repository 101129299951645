import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { AdminService } from '@/App/Services/Admin.service'
import { UserOnline } from '@/App/Services/interfaces/admin.interface'

const adminStore = namespace('Admin')

@Component
export default class UsersOnlinePage extends Vue {
  @adminStore.Mutation
  private readonly setUsersOnline: (users: UserOnline[]) => void

  @adminStore.State
  public readonly usersOnline: UserOnline[]

  public isLoading = false
  public search = ''
  public headers = [
    { text: 'Login', align: 'left', sortable: true, value: 'login' }
  ]

  private async mounted() {
    try {
      this.isLoading = true

      this.setUsersOnline(await AdminService.fetchUsersOnline())
    } catch {} finally {
      this.isLoading = false
    }
  }
}
